// GoogleChartsLoader.jsx
import React, { useEffect, useState } from 'react';

const GoogleChartsLoader = ({ onLoad }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGoogleCharts = () => {
      const script = document.createElement("script");
      script.src = "https://www.gstatic.com/charts/loader.js";
      script.onload = () => {
        window.google.charts.load("current", { packages: ["corechart"] });
        window.google.charts.setOnLoadCallback(() => {
          setLoading(false);
          if (onLoad) onLoad(); // Chama a função de callback passada como prop
        });
      };
      document.body.appendChild(script);
    };

    loadGoogleCharts();
  }, [onLoad]);

  if (loading) {
    return <div>Carregando gráficos...</div>;
  }

  return null; // Não renderiza nada quando está carregado
};

export default GoogleChartsLoader;
