import React, { useEffect, useState } from 'react';
import Menu from "../../components/menu/menu.jsx";
import api from "../../services/api.js";

// Função para formatar valores em reais
const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

const CaixaDashboard = () => {
  const [caixas, setCaixas] = useState([]);
  const [pagamentos, setPagamentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  // Função para detectar se é mobile ou desktop
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define mobile se a largura for menor ou igual a 768px
    };

    handleResize(); // Chama no primeiro render
    window.addEventListener('resize', handleResize); // Adiciona listener para mudanças de tamanho

    return () => {
      window.removeEventListener('resize', handleResize); // Remove o listener ao desmontar o componente
    };
  }, []);

  // Função para buscar os dados da API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/caixa/mostrarcaixas');
        setCaixas(response.data.caixas);
        setPagamentos(response.data.pagamentos);
        setLoading(false);
      } catch (err) {
        setError('Erro ao buscar dados.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="caixa" />
          </div>
          <div className="col py-3 me-3">
            <div className="mb-5"></div>
            <div className="row">
              <h2>Caixas Abertos</h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row', // Coluna no mobile, linha no desktop
                  flexWrap: 'wrap',
                  justifyContent: isMobile ? 'center' : 'space-between',
                }}
              >
                {caixas?.length === 0 ? (
                  <p>Nenhum caixa aberto.</p>
                ) : (
                  caixas?.map((caixa) => (
                    <div
                      key={caixa.CODIGO}
                      style={{
                        border: '5px solid #fff',
                        borderRadius: '8px',
                        margin: '10px',
                        padding: '15px',
                        width: isMobile ? '100%' : 'calc(33% - 20px)', // Ajusta a largura no mobile e desktop
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <h4>{caixa.DESCRICAO}</h4>
                      <p>Usuário: {caixa.ID_USUARIO}</p>
                      <p>Data Abertura: {caixa.DATA_ABERTURA}</p>
                      <p>Valor Abertura: {formatCurrency(caixa.ABERTURA)}</p>
                      <p>Valor Faturado: {formatCurrency(caixa.VALOR_FATURAMENTO)}</p>
                      <p>Valor Fechamento: {formatCurrency(caixa.VALOR_FECHAMENTO)}</p>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="bg-white p-4 rounded-4 border">
              <div className="d-flex justify-content-between mb-3">
                <h2>Movimentações por Forma de Pagamento</h2>
              </div>
              {isMobile ? (
                <div className="card-container">
                  {pagamentos.map((item) => (
                    <div className="card" key={item.HISTORICO}>
                      <div className="card-header">{item.HISTORICO}</div>
                      <div className="card-body">
                        <p><strong>Histórico:</strong> {item.HISTORICO}</p>
                        <p><strong>Entrada:</strong> {formatCurrency(item.ENTRADA)}</p>
                        <p><strong>Saída:</strong> {formatCurrency(item.SAIDA)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="data-table-container">
                  {pagamentos?.length === 0 ? (
                    <p>Nenhuma movimentação encontrada.</p>
                  ) : (
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th>Histórico</th>
                          <th>Entrada</th>
                          <th>Saída</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pagamentos?.map((pagamento, index) => (
                          <tr key={index}>
                            <td>{pagamento.HISTORICO}</td>
                            <td>{formatCurrency(pagamento.ENTRADA)}</td>
                            <td>{formatCurrency(pagamento.SAIDA)}</td>
                          </tr>
                        ))}

                        {/* Totalizador */}
                        <tr>
                          <td><strong>Total:</strong></td>
                          <td>
                            <strong>
                              {formatCurrency(
                                pagamentos.reduce((total, pagamento) => total + pagamento.ENTRADA, 0)
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {formatCurrency(
                                pagamentos.reduce((total, pagamento) => total + pagamento.SAIDA, 0)
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaixaDashboard;