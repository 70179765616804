import { Chart } from "react-google-charts";
import React from "react";

function Grafico(props) {
  const options = {
    legend: props.legenda ? {} : { position: "none" },
  };

  const handleClick = (e) => {
    e.stopPropagation(); // Impede que o evento de clique "suba" para outros elementos
    console.log("Gráfico clicado!");
  };

  return (
    <div className="card">
      <div className="card-header" onClick={handleClick}>
        {props.titulo}
      </div>
      <div className="card-body text-center">
        {/* Tratamento de erro para garantir que o gráfico não quebre a aplicação */}
        <ErrorBoundary>
          <Chart
            chartType={props.chartType}
            data={props.dados}
            width="100%"
            height="350px"
            options={options}
            legendToggle={true}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Atualiza o estado para mostrar a interface de fallback
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Erro no gráfico:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Interface de fallback
      return <div>Ocorreu um erro ao carregar o gráfico.</div>;
    }
    return this.props.children;
  }
}

export default Grafico;