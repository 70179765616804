import { useEffect, useState } from "react";
import { Dock } from "react-dock";
import "./ajustarestoque-cad.css";
import api from "../../services/api.js";

function AjustaestoqueCad(props){

    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [etapas, setEtapas] = useState([]);

    const [id_negocio, setIdNegocio] = useState(0);
    const [etapa, setEtapa] = useState("");
    const [codigo, setCodigo] = useState("0");    
    const [descricao, setDescricao] = useState("");    
    const [empresa, setEmpresa] = useState("");
    const [codbarra, setCodBarra] = useState("");
    const [referencia, setreferencia] = useState("");
    const [estoqueatual, setEstoqueAtual] = useState("");
    const [qtd, setQtd] = useState(0);

    const PesquisarCodigo = () => {
        
        console.log(codigo);
         if (codigo > 0) {        
            api.get("/produtos/"+codigo)
            .then((resp) => {
                 setCodigo(resp.data.CODIGO);
                 setDescricao(resp.data.DESCRICAO);
                 setCodBarra(resp.data.CODBARRA);
                 setreferencia(resp.data.REFERENCIA);
                 setEstoqueAtual(resp.data.QTD_ATUAL);
                 setQtd.setfocus;
            })
            .catch((err) => {
                setCodigo("0");
                alert("Erro ao Pesquisar Código: ");
            });
        }
      };
  
    function ComboEtapa(){
            setEtapas({"E":"ENTRADA","S":"SAIDA"});
    }

    function SalvarDados(){

        const params = {
            etapa,
            codigo,
            descricao,
            codbarra,
            referencia,
            estoqueatual,
            qtd
        };

        if (operacao == "new") {
            // NOVO REGISTRO........................
            api.post("/estoque", params)
            .then((resp) => {
                if (resp.status == 200) {
                    setShow(false);
                    props.onClose();
                } else {
                    alert("Erro ao inserir dados");
                }
            })
            .catch((err) => {
                setEtapas([]);
                alert("Erro ao inserir dados");
            });

        }// } else {
        //     // EDITAR REGISTRO EXISTENTE........................
        //     api.put("/ajustarestoque/" + id_negocio, params)
        //     .then((resp) => {
        //         if (resp.status == 200) {
        //             setShow(false);
        //             props.onClose();
        //         } else {
        //             alert("Erro ao editar dados");
        //         }
        //     })
        //     .catch((err) => {
        //         setEtapas([]);
        //         alert("Erro ao editar dados");
        //     });
        // }
        
    }

    useEffect(() => {        

        ComboEtapa();

        window.addEventListener('openSidebar', (param) => {

            if (param.detail.operacao == "new") {
                setIdNegocio(0);
                setEtapa("");
                setDescricao("");
                setreferencia("");
                setCodBarra("");
                setEstoqueAtual("");
                setQtd(0);
            } else {
                api.get("/stoque/" + param.detail.id_negocio)
                .then((resp) => {
                    setIdNegocio(resp.data.id_negocio);
                    setEtapa(resp.data.etapa);
                    setDescricao(resp.data.descricao);
                    setEmpresa(resp.data.empresa);
                    setContato(resp.data.contato);
                    setFone(resp.data.fone);
                    setEmail(resp.data.email);
                    setValor(resp.data.valor);
                })
                .catch((err) => {                    
                    alert("Erro ao carregar dados");
                    console.log(err);
                });
            }

            setShow(true);
            setOperacao(param.detail.operacao);
        });
    }, []);

    return <Dock position="right"
                 isVisible={show}
                 fluid={false}
                 size={410}   
                 onVisibleChange={(visible) => {
                    setShow(visible);
                 }}         
                 >

        <div className="container-fluid h-100 pt-4 sidebar p-4 ">

            <h4 className="d-inline">
                {operacao == "edit" ? "Editar Ajuste de Estoque" : "Novo Ajuste de Estoque"}
            </h4>

            <div className="row tela-cad">
                <div className="col-12 mt-4">
                    <small className="text-secondary">Movimento</small>
                    <div className="form-control mb-2">
                    <select name="etapa" id="etapa" onChange={(e) => setEtapa(e.target.value)} value={etapa}>
                        <option value="">Selecione o Movimento</option>
                        <option key="E" value="E" selected>ENTRADA</option>
                        <option key="S" value="S">SAÍDA</option>
                    </select>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Código do Produto</small>
                    <input type="text" className="form-control" onChange={(e) => setCodigo(e.target.value)} value={codigo} />
                    <button className="btn btn-primary" onClick={PesquisarCodigo}>Pesquisar</button>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Descrição</small>
                    <input type="text" className="form-control" onChange={(e) => setDescricao(e.target.value)} value={descricao} disabled/>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Código de Barras</small>
                    <input type="text" className="form-control" onChange={(e) => setCodBarra(e.target.value)} value={codbarra} disabled/>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Referência</small>
                    <input type="text" className="form-control" onChange={(e) => setreferencia(e.target.value)} value={referencia} disabled/>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Estoque Atual</small>
                    <input type="text" className="form-control" onChange={(e) => setEstoqueAtual(e.target.value)} value={estoqueatual} disabled/>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Quantidade</small>
                    <input type="text" className="form-control" onChange={(e) => setQtd(e.target.value)} value={qtd} />
                </div>

            </div>

            <div className="row align-items-end footer">
                <div className="text-center">
                    <button className="btn btn-outline-primary me-3" onClick={() => setShow(false)}>Cancelar</button>
                    <button className="btn btn-primary" onClick={SalvarDados}>Salvar Dados</button>
                </div> 
            </div>

        </div>
        

    </Dock>
}

export default AjustaestoqueCad;