import React, { useEffect, useState, useCallback, useMemo } from "react";
import Busca from "../../components/busca/busca.jsx";
import Menu from "../../components/menu/menu.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import ProdutoCad from "../produto-cad/produto-cad.jsx";
import api from "../../services/api.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./modal.css"; // Crie um arquivo CSS para estilizar o modal

function Produtos() {
  const [termoBusca, setTermoBusca] = useState(""); // Termo da busca
  const [criterioBusca, setCriterioBusca] = useState("descricao"); // Critério da busca
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [isMobile, setIsMobile] = useState(false);
  const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
  const [dados, setDados] = useState([]);
  const [rprodutos, setRProdutos] = useState([]);
  const [total_registros, setTotalRegistros] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false); // Controle da visibilidade do modal

  // Definição das colunas da tabela
  const columns = useMemo(() => [
    { name: 'Código', selector: row => row.CODIGO, sortable: true, width: "120px" },
    { name: 'Descrição', selector: row => row.DESCRICAO, sortable: true, compact: true },
    { name: 'Grupo', selector: row => row.GRUPO_NOME, sortable: true },
    {
      name: 'Valor Varejo',
      selector: row => row.PR_VENDA,
      sortable: true,
      right: true,
      format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.PR_VENDA)
    },
    {
      name: 'Valor Atacado',
      selector: row => row.PRECO_ATACADO,
      sortable: true,
      right: true,
      format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.PRECO_ATACADO)
    },
    { name: 'Estoque Atual', selector: row => row.QTD_ATUAL, sortable: true, right: true },
    {
      cell: (row) => (
        <>
          <button onClick={() => Editar(row.CODIGO)} className="btn btn-primary ms-3"><i className="bi bi-pencil-square"></i></button>
          <button onClick={() => Excluir(row.CODIGO)} className="btn btn-danger ms-3"><i className="bi bi-trash3-fill"></i></button>
        </>
      ),
      width: "200px",
      right: true
    }
  ], []);

  // Opções de paginação
  const paginationOptions = {
    rowsPerPageText: 'Registros por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos'
  };

  // Função para listar produtos com paginação e busca
  const ListarProdutos = useCallback(async (pagina = 1, qtd_reg_pagina = 10, termoBusca = "", criterioBusca = "descricao") => {
    setLoading(true);
    try {
      const resp = await api.get("/produtos", { params: { pagina, qtd_reg_pagina, busca: termoBusca, criterio: criterioBusca } });
      setTotalRegistros(resp.data.total_registros);
      setDados(resp.data.registros);
    } catch (err) {
      setTotalRegistros(0);
      setDados([]);
      alert("Erro ao carregar Produtos");
    } finally {
      setLoading(false);
    }
  }, []);

  // Função para excluir produto
  const Excluir = useCallback((id) => {
    confirmAlert({
      title: 'Exclusão',
      message: 'Confirma exclusão do Produto?',
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            try {
              await api.delete("/produtos/" + id);
              ListarProdutos(1, qtd_reg_pagina, termoBusca, criterioBusca);
            } catch (err) {
              alert("Erro ao excluir o Produto");
            }
          }
        },
        { label: 'Não' }
      ]
    });
  }, [currentPage, qtd_reg_pagina, ListarProdutos]);

  // Função para editar produto
  const Editar = (id) => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "edit", codigo: id } });
    window.dispatchEvent(event);
  };

  // Função para criar novo produto
  const Novo = () => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "new" } });
    window.dispatchEvent(event);
  };

  // Alteração na quantidade de registros por página
  const ChangePerRows = (newQtd, page) => {
    setQtdRegPagina(newQtd);
    setCurrentPage(page);
    ListarProdutos(page, newQtd, termoBusca, criterioBusca);
  };

  // Alteração da página
  const ChangePage = (page) => {
    setCurrentPage(page);
    ListarProdutos(page, qtd_reg_pagina, termoBusca, criterioBusca);
  };

  // Função para atualizar a lista de produtos
  const RefreshDados = () => ListarProdutos(1, qtd_reg_pagina, termoBusca, criterioBusca);

  // Manipulação do termo de busca
  function handleBuscaChange(e) {
    setTermoBusca(e.target.value);
  }

  // Manipulação do critério de busca
  function handleCriterioChange(e) {
    setCriterioBusca(e.target.value);
  }

  // Função para executar a busca
  function handleBuscar() {
    ListarProdutos(1, qtd_reg_pagina, termoBusca, criterioBusca);
  }

  // Função para gerar PDF dos produtos
  const generatePDF = async () => {

    setLoading(true); // Inicia o carregamento
    setModalVisible(true); // Mostra o modal quando começa a gerar o PDF
    
    try {
      const response = await api.get("/produtos/listagempdf"); // Busca os produtos diretamente
      const rprodutos = response.data.registros;
    
      console.log(rprodutos); // Verifique a estrutura do retorno
    
        // Verifique se o retorno é um array
        if (!Array.isArray(rprodutos)) {
          throw new Error("Os dados retornados não são um array");
        }


      const doc = new jsPDF();

      // Título do PDF
      doc.setFontSize(18);
      doc.text("Relatório de Produtos", 14, 22);

      // Subtítulo
      doc.setFontSize(12);
      doc.text("Gerado em: " + new Date().toLocaleDateString(), 14, 32);

      // Cabeçalhos da tabela
      const tableColumn = ["Código", "Código Barras", "Descrição", "Qtd", "Contagem"];

      // Linhas da tabela preenchidas com dados da API
      const tableRows = rprodutos.map((rproduto) => [
        rproduto.CODIGO,
        rproduto.CODBARRA,
        rproduto.DESCRICAO,
        rproduto.QTD_ATUAL,
        '________',
      ]);

      // Gerar tabela no PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 40,
      });

      // Salvar o PDF
      doc.save("relatorio_produtos.pdf");
    } catch (error) {
      console.error("Erro ao gerar PDF", error);
    }finally {
      setLoading(false); // Finaliza o carregamento
      setModalVisible(false); // Esconde o modal quando a geração do PDF termina
    }
  };

  // Função para detectar ambiente (desktop ou mobile)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    handleResize();

    // Carrega a lista de produtos na montagem do componente
    ListarProdutos(1, qtd_reg_pagina);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, [ListarProdutos, qtd_reg_pagina]);

return (
    <>
      <ProdutoCad onClose={RefreshDados} />
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="produtos" />
          </div>
          <div className="col py-3 me-3">
            <div className="mb-5">
              <Busca
                criterioBusca={criterioBusca}
                handleCriterioChange={handleCriterioChange}
                termoBusca={termoBusca}
                handleBuscaChange={handleBuscaChange}
                onBuscar={handleBuscar}
                texto="Digite o termo de busca"
            />
            </div>
            <div className="bg-white p-4 rounded-4 border">
              <div className="d-flex justify-content-between mb-3">
                <h2>Produtos</h2>
                {isMobile ? (
                  <>
                    <button onClick={generatePDF} className="btn btn-primary ms-auto" disabled={loading}><i class="bi bi-file-pdf-fill"></i></button>
                    <button onClick={Novo} className="btn btn-primary ms-auto">
                      <i className="bi bi-plus-lg me-2"></i>
                    </button>
                      {/* Modal de carregamento */}
                      {modalVisible && (
                        <div className="modal">
                          <div className="modal-content">
                            <p>Gerando PDF, por favor aguarde...</p>
                          </div>
                        </div>
                      )}                    
                  </>
                ) : (
                  <div>
                    <button onClick={generatePDF} className="btn btn-primary ms-4" disabled={loading}><i class="bi bi-file-pdf-fill"></i> Listagem</button>
                    <button onClick={Novo} className="btn btn-primary ms-4">
                      <i className="bi bi-plus-lg me-2"></i>Novo Produto
                    </button>
                      {/* Modal de carregamento */}
                      {modalVisible && (
                        <div className="modal">
                          <div className="modal-content">
                            <p>Gerando PDF, por favor aguarde...</p>
                          </div>
                        </div>
                      )}                    
                  </div>
                )}
              </div>
              {isMobile ? (
                <div className="card-container">
                  {dados.map((item) => (
                    <div className="card" key={item.CODIGO}>
                      <div className="card-header">{item.DESCRCAO}</div>
                      <div className="card-body">
                        <p><strong>Código:</strong> {item.CODIGO}</p>
                        <p><strong>Descrição:</strong> {item.DESCRICAO}</p>
                        <p><strong>Grupo:</strong> {item.GRUPO_NOME}</p>
                        <p><strong>Estoque:</strong> {item.QTD_ATUAL}</p>
                        <p><strong>Vl. Varejo:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.PR_VENDA)}</p>
                        <p><strong>Vl. Atacado:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.PRECO_ATACADO)}</p>
                        <button onClick={() => Editar(item.CODIGO)} className="btn btn-primary ms-3">
                          <i className="bi bi-pencil-square"></i>
                        </button>
                        <button onClick={() => Excluir(item.CODIGO)} className="btn btn-danger ms-3">
                          <i className="bi bi-trash3-fill"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="data-table-container">
                  <DataTable
                    columns={columns}
                    data={dados}
                    pagination={true}
                    paginationComponentOptions={paginationOptions}
                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                    paginationServer={true}
                    paginationTotalRows={total_registros}
                    onChangeRowsPerPage={ChangePerRows}
                    onChangePage={ChangePage}
                    progressPending={loading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Produtos;