import React, { useEffect, useState, useCallback, useMemo } from "react";
import Busca from "../../components/busca/busca.jsx";
import Menu from "../../components/menu/menu.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import AjustaEstoqueCad from "../ajustarestoque-cad/ajustarestoque-cad.jsx";
import api from "../../services/api.js";

function AjustarEstoque() {
  const [termoBusca, setTermoBusca] = useState(""); // Termo da busca
  const [criterioBusca, setCriterioBusca] = useState("descricao"); // Critério da busca  
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [isMobile, setIsMobile] = useState(false);
  const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
  const [dados, setDados] = useState([]);
  const [total_registros, setTotalRegistros] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = useMemo(() => [
    { name: 'Código', selector: row => row.CODIGO, sortable: true, width: "120px" },
    {
    name: 'Data',
    selector: row => new Date(row.DATA).toLocaleDateString('pt-BR', { timeZone: 'UTC' }), // Formata a data no padrão DD/MM/YYYY
    sortable: true
    },
    { name: 'Descrição do produto', selector: row => row.PRODUTO_DESCRICAO, sortable: true, compact: true },
    { name: 'Tipo', selector: row => row.TP_MOVIMENTO, sortable: true },
    { 
      name: 'Qtd Ajustada', 
      selector: row => row.QTD_A, 
      sortable: true, 
      right: true
    }
  ], []);

  const paginationOptions = {        
    rowsPerPageText: 'Registros por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos'        
  };

  const ListarProdutos = useCallback(async (pagina = 1, qtd_reg_pagina = 10, termoBusca = "", criterioBusca = "descricao") => {
    setLoading(true);
    try {
      const resp = await api.get("/estoque", { params: { pagina, qtd_reg_pagina, busca: termoBusca, criterio: criterioBusca } });
      setTotalRegistros(resp.data.total_registros);
      setDados(resp.data.registros);
    } catch (err) {
      setTotalRegistros(0);
      setDados([]);
      alert("Erro ao carregar Produtos com Estoque Ajustado");
    } finally {
      setLoading(false);
    }
  }, []);

  const Excluir = useCallback((id) => {
    confirmAlert({
      title: 'Exclusão',
      message: 'Confirma exclusão do Produto?',
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            try {
              await api.delete("/estoque/" + id);
              ListarProdutos(currentPage, qtd_reg_pagina);
            } catch (err) {
              alert("Erro ao excluir o Produto");
            }
          }
        },
        { label: 'Não' }
      ]
    });
  }, [currentPage, qtd_reg_pagina, ListarProdutos]);

  const Editar = (id) => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "edit", codigo: id } });
    window.dispatchEvent(event);
  };

  const Novo = () => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "new" } });
    window.dispatchEvent(event);
  };

  const ChangePerRows = (newQtd, page, termoBusca, criterioBusca) => {
    setQtdRegPagina(newQtd);
    setCurrentPage(page);
    ListarProdutos(page, newQtd, termoBusca, criterioBusca);
  };

  const ChangePage = (page) => {
    setCurrentPage(page);
    ListarProdutos(page, qtd_reg_pagina, termoBusca, criterioBusca);
  };

  const RefreshDados = () => ListarProdutos(1, qtd_reg_pagina, termoBusca, criterioBusca);
    function handleBuscaChange(e) {
        setTermoBusca(e.target.value); // Atualiza o termo de busca
    }

    function handleCriterioChange(e) {
        setCriterioBusca(e.target.value); // Atualiza o critério de busca
    }

    function handleBuscar() {
        ListarProdutos(1, qtd_reg_pagina, termoBusca, criterioBusca); // Executa a busca com termo e critério
    }
    
  // Função para detectar ambiente (desktop ou mobile)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    handleResize();

    // Carrega a lista de produtos na montagem do componente
    ListarProdutos(1, qtd_reg_pagina);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, [ListarProdutos, qtd_reg_pagina]);

  return (
    <>
    <AjustaEstoqueCad onClose={RefreshDados} />
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="ajustarestoque" />
          </div>
          <div className="col py-3 me-3">
            <div className="mb-5">
              <Busca
                criterioBusca={criterioBusca}
                handleCriterioChange={handleCriterioChange}
                termoBusca={termoBusca}
                handleBuscaChange={handleBuscaChange}
                onBuscar={handleBuscar}
                texto="Digite o termo de busca"
            />
            </div>
            <div className="bg-white p-4 rounded-4 border">
              <div className="d-flex justify-content-between mb-3">
                <h2>Ajusta Estoque</h2>
                {isMobile ? (
                  <button onClick={Novo} className="btn btn-primary ms-auto">
                    <i className="bi bi-plus-lg me-2"></i>
                  </button>
                ) : (
                  <button onClick={Novo} className="btn btn-primary ms-4">
                    <i className="bi bi-plus-lg me-2"></i>Novo Produto
                  </button>
                )}
              </div>
              {isMobile ? (
                <div className="card-container">
                  {dados.map((item) => (
                    <div className="card" key={item.CODIGO}>
                      <div className="card-header">{item.PRODUTO_DESCRICAO}</div>
                      <div className="card-body">
                        <p><strong>Código:</strong> {item.CODIGO}</p>
                        <p><strong>Data:</strong> {item.DATA}</p>
                        <p><strong>Descrição:</strong> {item.PRODUTO_DESCRICAO}</p>
                        <p><strong>Tipo:</strong> {item.TP_MOVIMENTO}</p>
                        <p><strong>Qtd Ajustada:</strong> {item.QTD_A}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="data-table-container">
                  <DataTable
                    columns={columns}
                    data={dados}
                    pagination={true}
                    paginationComponentOptions={paginationOptions}
                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                    paginationServer={true}
                    paginationTotalRows={total_registros}
                    onChangeRowsPerPage={ChangePerRows}
                    onChangePage={ChangePage}
                    progressPending={loading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AjustarEstoque;
