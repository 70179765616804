import Menu from "../../components/menu/menu.jsx";
import Busca from "../../components/busca/busca.jsx";
import Indicador from "../../components/indicador/indicador.jsx";
import Grafico from "../../components/grafico/grafico.jsx";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary.jsx";
import { useEffect, useState } from "react";
import api from "../../services/api.js";
import { isMobile } from 'react-device-detect'; // Importando a detecção de dispositivo
import GoogleChartsLoader from './GoogleChartsLoader'; // Importa o componente

function Dashboard() {
  const [dadosIndicadores, setDadosIndicadores] = useState({
    valor_mes_anterior: 0,
    qtd_mes_anterior: 0,
    valor_mes: 0,
    qtd_mes: 0,
    valor_dia: 0,
    qtd_dia: 0,
    valor_ontem: 0,
    qtd_ontem: 0,
  });

  const [dadosMensal, setDadosMensal] = useState([["Mês", "Valor"], [0, 0]]);
  const [dadosAnual, setDadosAnual] = useState([["Ano", "Valor"], [0, 0]]);
  const [dadosprodutos, setDadosProdutos] = useState([]);
  const [dadospagamento, setDadospagamento] = useState([]);
  const [dadospagamentomes, setDadospagamentomes] = useState([]);
  const [dadospagamentodia, setDadospagamentodia] = useState([]);
  const [chartsLoaded, setChartsLoaded] = useState(false); // Estado para verificar se os gráficos estão carregados
  
  function MontarDashboard() {
    Promise.all([
      api.get("/dashboard/resumos"),
      api.get("/dashboard/mensal"),
      api.get("/dashboard/anual"),
      api.get("/dashboard/produtotop"),
      api.get("/dashboard/pagamentomes"),
      api.get("/dashboard/pagamentomesatual"),
      api.get("/dashboard/pagamentodia"),
    ])
      .then(([
        resumosResp,
        mensalResp,
        anualResp,
        produtosResp,
        pagamentoResp,
        pagamentoMesResp,
        pagamentoDiaResp,
      ]) => {
        setDadosIndicadores(resumosResp.data);
        setDadosMensal(mensalResp.data);
        setDadosAnual(anualResp.data);
        setDadosProdutos(produtosResp.data);
        setDadospagamento(pagamentoResp.data);
        setDadospagamentomes(pagamentoMesResp.data);
        setDadospagamentodia(pagamentoDiaResp.data);
      })
      .catch((err) => {
        console.log("Erro ao carregar dados:", err);
      });
  }
  
const handleClick = (e) => {
  e.stopPropagation(); // Impede que o evento de clique afete elementos pai
  console.log('Gráfico clicado!');
};
  useEffect(() => {
            MontarDashboard();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="dashboard" />
          </div>

          <div className="col py-3 me-3">
            <div className="d-flex justify-content-between">
              <h1>Dashboard</h1>
              <button onClick={MontarDashboard} className="btn btn-primary ms-4">Atualizar</button>
            </div>
            
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <Indicador
                  titulo="Vendas no mês Anterior"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.valor_mes_anterior)}
                  rodape={`${dadosIndicadores.qtd_mes_anterior} Vendas`}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <Indicador
                  titulo="Vendas no mês"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.valor_mes)}
                  rodape={`${dadosIndicadores.qtd_mes} Vendas`}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <Indicador
                  titulo="Vendas de Ontem"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.valor_ontem)}
                  rodape={`${dadosIndicadores.qtd_ontem} Vendas`}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <Indicador
                  titulo="Vendas de hoje"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.valor_dia)}
                  rodape={`${dadosIndicadores.qtd_dia} Vendas`}
                />
              </div>

              <div className="col-12 col-md-6 p-3">
                <Grafico
                  titulo="Venda por Mês"
                  chartType="PieChart"
                  dados={dadosMensal}
                  legenda={true}
                  onClick={handleClick} // Evento diretamente no gráfico
                />
              </div>
              <div className="col-12 col-md-6 p-3">
                <Grafico
                  titulo="Venda por Ano"
                  chartType="PieChart"
                  dados={dadosAnual}
                  legenda={true}
                  onClick={handleClick} // Evento diretamente no gráfico
                />
              </div>
              <div className="col-12 col-md-6 p-3">
                <Grafico
                  titulo="Venda por produto (top 9)"
                  chartType="PieChart"
                  dados={dadosprodutos}
                  legenda={true}
                  onClick={handleClick} // Evento diretamente no gráfico
                />
              </div>
              <div className="col-12 col-md-6 p-3">
                <Grafico
                  titulo="Forma de Pagamento por Mês"
                  chartType="Bar"
                  dados={dadospagamento}
                  legenda={false}
                  onClick={handleClick} // Evento diretamente no gráfico
                />
              </div>
              <div className="col-12 col-md-6 p-3">
                <Grafico
                  titulo="Forma de Pagamento do Mês Atual"
                  chartType="PieChart"
                  dados={dadospagamentomes}
                  legenda={true}
                  onClick={handleClick} // Evento diretamente no gráfico
                />
              </div>
              <div className="col-12 col-md-6 p-3">
              <ErrorBoundary>
                <Grafico
                  titulo="Forma de Pagamento de Hoje"
                  chartType="PieChart"
                  dados={dadospagamentodia}
                  legenda={true}
                  onClick={handleClick} // Evento diretamente no gráfico
                />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;