// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar .footer{
    height: 65px;
}


.sidebar .tela-cad{
    height: calc(100% - 95px);
    overflow: auto;
    align-content: flex-start;
}

.sidebar h4{
    height: 30px;
    margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ajustarestoque-cad/ajustarestoque-cad.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;;AAGA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".sidebar .footer{\n    height: 65px;\n}\n\n\n.sidebar .tela-cad{\n    height: calc(100% - 95px);\n    overflow: auto;\n    align-content: flex-start;\n}\n\n.sidebar h4{\n    height: 30px;\n    margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
