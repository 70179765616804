import { useEffect, useState } from "react";
import { Dock } from "react-dock";
import "./produto-cad.css";
import api from "../../services/api.js";

function ProdutoCad(props){

    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [grupos, setGrupos] = useState([]);

    const [codigo, setIdProduto] = useState(0);
    const [tipo, setTipo] = useState("");
    const [grupo, setGrupo] = useState([""]);
    const [referencia, setReferencia] = useState("");
    const [codbarra, setCodBarra] = useState("");
    const [descricao, setDescricao] = useState("");    
    const [grupo_nome, setGrupoNome] = useState("");
    const [precocompra, setPrecoCompra] = useState(0);
    const [precocusto, setPrecoCusto] = useState(0);
    const [pr_venda, setPrVenda] = useState(0);
    const [qtdatacado, setQtdAtacado] = useState(2);
    const [preco_atacado, setPrecoAtacado] = useState(0);
    const [qtd_atual, setQtd_Atual] = useState("");
    //const [valor, setValor] = useState(0);
  const formatToCurrency = (value) => {
    return value
      ? `R$ ${parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      : '';
  };
  
    function calculaDigEAN13(cod) {
      // Função para verificar se um número é par
      const isPar = (num) => num % 2 === 0;
    
      let somaPar = 0;
      let somaImpar = 0;
    
      // Itera pelos primeiros 12 caracteres do código
      for (let i = 0; i < 12; i++) {
        if (isPar(i + 1)) {
          somaPar += parseInt(cod[i], 10);
        } else {
          somaImpar += parseInt(cod[i], 10);
        }
      }
    
      // Multiplica a soma dos pares por 3
      somaPar *= 3;
    
      let total = somaPar + somaImpar;
      let i = 0;
    
      // Incrementa i até que seja maior ou igual ao total
      while (i < total) {
        i += 10;
      }
    
      // Retorna o dígito verificador
      return (i - total).toString();
    }

    function geraEAN(codigo) {
      // Formata o código para ter exatamente 9 dígitos com zeros à esquerda
      const codigoFormatado = codigo.toString().padStart(9, '0');
      
      // Adiciona o prefixo '777' ao código formatado
      let resultado = '777' + codigoFormatado;
      
      // Chama a função que calcula o dígito verificador EAN-13
      resultado += calculaDigEAN13(resultado);
      
      return resultado;
    }
  
  const AtacadoChange = (e) => {
    // Remove tudo que não for número e formata o valor
    const value = e.target.value.replace(/[^0-9.,]/g, '');
    setPrecoAtacado(formatToCurrency(value));
  };
  const VendaChange = (e) => {
    // Remove tudo que não for número e formata o valor
    const value = e.target.value.replace(/[^0-9.,]/g, '');
    setPrVenda(formatToCurrency(value));
  };  
  
    const CompraChange = (e) => {
    // Remove tudo que não for número e formata o valor
    const value = e.target.value.replace(/[^0-9.,]/g, '');
    setPrecoCompra(formatToCurrency(value));
  };
  
  const CustoChange = (e) => {
    // Remove tudo que não for número e formata o valor
    const value = e.target.value.replace(/[^0-9.,]/g, '');
    setPrecoCusto(formatToCurrency(value));
  };  

 const handleGerarCodigo = () => {
     if (codigo === 0) {
        const novoCodigo = gerarcodigo();
        //console.log(novoCodigo);
        setIdProduto(novoCodigo);
    }
  };
  
 const handleGenerateEAN = () => {
        // api.get("/produtos/gerarcodigo")
        // .then((resp) => {
        // //console.log(resp.data[0].CODIGO);
        //     // Verifique qual propriedade do objeto `resp` contém o código que você deseja
        //     const codigoGerado = resp.data[0].CODIGO; // Ajuste conforme a estrutura do objeto retornado
        //     setIdProduto(codigoGerado); // Atribua o valor correto ao estado
        //     const novoEAN = geraEAN(resp.data[0].CODIGO);
        //     setCodBarra(novoEAN); // Atualiza o campo referência com o EAN gerado
        //     setReferencia(novoEAN); // Atualiza o campo referência com o EAN gerado            
        // })
        // .catch((err) => {
        //     setIdProduto(0);
        //     alert("Erro ao Gerar Código");
        // });     
    if (referencia.trim() === '') {
      const novoEAN = geraEAN(codigo);
      setCodBarra(novoEAN); // Atualiza o campo referência com o EAN gerado
      setReferencia(novoEAN); // Atualiza o campo referência com o EAN gerado
    }
  };
  
    function ComboGrupos(){
        api.get("/grupos")
        .then((resp) => {
            setGrupos(resp.data);
        })
        .catch((err) => {
            setGrupos([]);
            alert("Erro ao montar combo");
        });
    }

    function gerarcodigo(){
        api.get("/produtos/gerarcodigo")
        .then((resp) => {
            // Verifique qual propriedade do objeto `resp` contém o código que você deseja
            const codigoGerado = resp.data[0].CODIGO; // Ajuste conforme a estrutura do objeto retornado
            setIdProduto(codigoGerado); // Atribua o valor correto ao estado
          const novoEAN = geraEAN(codigoGerado);
          setCodBarra(novoEAN); // Atualiza o campo referência com o EAN gerado
          setReferencia(novoEAN); // Atualiza o campo referência com o EAN gerado            
        })
        .catch((err) => {
            setIdProduto(0);
            alert("Erro ao Gerar Código");
        });
    }

    function SalvarDados(){

        const params = {
            id_usuario: localStorage.getItem("id_usuario"),
            codigo,
            tipo,
            grupo,
            referencia,
            codbarra,
            descricao,
            grupo_nome,
            precocompra,
            precocusto,
            qtdatacado,
            pr_venda,
            preco_atacado,
            qtd_atual
        };

        if (operacao == "new") {
            // NOVO REGISTRO........................
            api.post("/produtos", params)
            .then((resp) => {
                if (resp.status == 200) {
                    setShow(false);
                    props.onClose();
                } else {
                    alert("Erro ao inserir dados");
                }
            })
            .catch((err) => {
                //setEtapas([]);
                alert("Erro ao inserir dados");
            });

        } else {
            // EDITAR REGISTRO EXISTENTE........................
            api.put("/produtos/" + codigo, params)
            .then((resp) => {
                if (resp.status == 200) {
                    setShow(false);
                    props.onClose();
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {
                setGrupos([]);
                alert("Erro ao editar dados");
            });
        }
        
    }

useEffect(() => {
    ComboGrupos();
    
    const handleSidebarEvent = (param) => {
        if (param.detail.operacao === "new") {
            gerarcodigo();
            //setIdProduto();
            //setReferencia("");
            setDescricao("");
            setGrupo("");
            setTipo("00-MERCADORIA PARA REVENDA");
            setPrecoCompra(0);
            setPrecoCusto(0);
            setPrVenda(0);
        } else {
            api.get("/produtos/" + param.detail.codigo)
            .then((resp) => {
                setIdProduto(resp.data.CODIGO);
                setGrupo(resp.data.GRUPO);
                setCodBarra(resp.data.CODBARRA);
                setReferencia(resp.data.REFERENCIA);
                setDescricao(resp.data.DESCRICAO);
                setTipo(resp.data.TIPO);
                setPrecoCompra(resp.data.PR_CUSTO);
                setPrecoCusto(resp.data.PR_CUSTO2);
                setPrVenda(resp.data.PR_VENDA);
                setQtdAtacado(resp.data.QTD_ATACADO);
                setPrecoAtacado(resp.data.PRECO_ATACADO);
                setQtd_Atual(resp.data.QTD_ATUAL);
            })
            .catch((err) => {                    
                alert("Erro ao carregar dados");
                console.log(err);
            });
        }

        setShow(true);
        setOperacao(param.detail.operacao);
    };

    window.addEventListener('openSidebar', handleSidebarEvent);

    // Cleanup event listener on component unmount
    return () => {
        window.removeEventListener('openSidebar', handleSidebarEvent);
    };
}, []);


    return <Dock position="right"
                 isVisible={show}
                 fluid={false}
                 size={410}   
                 onVisibleChange={(visible) => {
                    setShow(visible);
                 }}         
                 >

        <div className="container-fluid h-100 pt-4 sidebar p-4 ">

            <h4 className="d-inline">
                {operacao == "edit" ? "Editar Produto" : "Novo Produto"}
            </h4>

            <div className="row tela-cad">
                <div className="col-12 mt-4">
                    <small className="text-secondary">Grupo</small>
                    <div className="form-control mb-2">
                        <select name="grupo" id="grupo" onChange={(e) => setGrupo(e.target.value)} value={grupo}>
                            <option value="">Selecione o Grupo</option>
                            {
                                // Aqui deve ser `grupo` e não `grupos`
                                grupos.map((item) => {
                                    return <option key={item.CODIGO} value={item.CODIGO}>{item.DESCRICAO}</option>
                                })
                            }
                        </select>
                </div>
                </div>            
                <div className="col-12 mt-3">
                    <small className="text-secondary">Códigos</small>
                    <input type="text" className="form-control" onChange={(e) => setIdProduto(e.target.value)} value={codigo} disabled />
                </div>
                
                <div className="col-12 mt-3">
                    <small className="text-secondary">Tipo</small>
                    <input type="text" className="form-control" onChange={(e) => setTipo(e.target.value)} value={tipo} disabled/>
                </div>
                
                <div className="col-12 mt-3">
                    <small className="text-secondary">Código de Barras</small>
                    <input type="text" className="form-control" onChange={(e) => setCodBarra(e.target.value)} value={codbarra} disabled/>
                    <button className="btn btn-primary" onClick={handleGenerateEAN}>Gerar EAN</button>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Referência</small>
                    <input type="text" className="form-control" onChange={(e) => setReferencia(e.target.value)} value={referencia} disabled/>
                </div>
                
                <div className="col-12 mt-3">
                    <small className="text-secondary">Descrição</small>
                    <input type="text" className="form-control" onChange={(e) => setDescricao(e.target.value)} value={descricao} />
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Preço Compra</small>
                    <input type="text" className="form-control" onChange={(e) => setPrecoCompra(e.target.value)} value={precocompra} /*onChange={CompraChange}*//>
                </div>
                
                <div className="col-12 mt-3">
                    <small className="text-secondary">Preço Custo</small>
                    <input type="text" className="form-control" onChange={(e) => setPrecoCusto(e.target.value)} value={precocusto} /*onChange={CustoChange}*//>
                </div>
                
                <div className="col-12 mt-3">
                    <small className="text-secondary">Preço Unidade</small>
                    <input type="text" className="form-control" onChange={(e) => setPrVenda(e.target.value)} value={pr_venda} /*onChange={VendaChange}*//>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Qtd Atacado</small>
                    <input type="text" className="form-control" onChange={(e) => setQtdAtacado(e.target.value)} value={qtdatacado} />
                </div>
                
                <div className="col-12 mt-3">
                    <small className="text-secondary">Preço Atacado</small>
                    <input type="text" className="form-control" onChange={(e) => setPrecoAtacado(e.target.value)} value={preco_atacado} /*onChange={AtacadoChange}*//>
                </div>

                <div className="col-12 mt-3">
                    <small className="text-secondary">Esoque Atual</small>
                    <input type="text" className="form-control" onChange={(e) => setQtd_Atual(e.target.value)} value={qtd_atual} disabled />
                </div>

            </div>

            <div className="row align-items-end footer">
                <div className="text-center">
                    <button className="btn btn-outline-primary me-3" onClick={() => setShow(false)}>Cancelar</button>
                    <button className="btn btn-primary" onClick={SalvarDados}>Salvar Dados</button>
                </div> 
            </div>

        </div>
        

    </Dock>
}

export default ProdutoCad;